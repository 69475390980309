import React from 'react';
import Helmet from 'react-helmet';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import Page from '../../components/Page';
import Theme from '../../components/Theme';
import { themeLightGrey, themeSecondary } from '../../styles/constants';
import ComplaintsAndFeedbackData from '../Static/components/ComplaintsAndFeedback';
import ComplaintsAndFeedbackForm from '../Static/components/ComplaintsAndFeedbackForm';

function ComplaintsAndFeedback() {
  return (
    <>
      <Helmet>
        <title>We’re here to help. Share your Feedback or Complaint</title>
        <meta
          name="description"
          content="Share your feedback or complaints with us! We're dedicated to improving our services and ensuring you receive the support you deserve."
        />
      </Helmet>
      <Theme theme={themeSecondary}>
        <Navigation />
        <Page>
          <ComplaintsAndFeedbackData />
          {window.innerWidth > 768 ? (
            <Theme theme={themeLightGrey}>
              <ComplaintsAndFeedbackForm />
            </Theme>
          ) : (
            <Theme theme={themeSecondary}>
              <ComplaintsAndFeedbackForm />
            </Theme>
          )}
        </Page>
        <Footer />
      </Theme>
    </>
  );
}

export default ComplaintsAndFeedback;
